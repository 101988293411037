import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import Heading from "../../components/headline";
import DynamicContent from "../../components/dynamicContent";
import { Grid, Typography, useScrollTrigger, Slide } from "@material-ui/core";
import { ContactSupportOutlined } from "@material-ui/icons";
// import useScrollTrigger from "@material-ui/core/useScrollTrigger";
const parse = require("html-react-parser");

const HideOnScroll = (props) => {
  const trigger = useScrollTrigger();
  return (
    <Slide in={!trigger}>
      <div>Hello</div>
    </Slide>
  );
};

const QueerPage = () => {
  const data = useStaticQuery(query);

  console.log(data);
  const { strapiQueer } = data;
  const { Content, Headline } = strapiQueer;
  return (
    <Layout>
      <Heading headline={Headline} />

      <div className={"dynamic-content"}>
        {Content.map((contentBlock) => (
          <DynamicContent block={contentBlock} />
        ))}
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiQueer {
      Content {
        answer
        artistName
        blockName
        eventName
        heading
        id
        instagramUrl
        musicUrl
        question
        quoteAuthor
        quoteText
        textField
        SublineLink {
          authorPublication
          discoverMore
        }
        Image {
          childCloudinaryAsset {
            fluid(maxWidth: 1024) {
              ...CloudinaryAssetFluid
            }
          }
        }
      }
      Headline {
        blockName
        heading
        id
        textField
      }
      name
      slug
    }
  }
`;

export default QueerPage;
